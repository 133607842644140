<template>
  <div id="logins">
    <img class="head_img" src="@img/login/login.png" alt="" />
    <div class="table">
      <!-- 标题 -->
      <p class="head_name">{{language.l_3}}</p>
      <!-- 表单 -->
      <van-form @submit="onSubmit">
         <!-- 邮箱 -->
        <p class="mailbox">{{language.l_101}}</p>
        <van-field v-model="email" :placeholder="language.l_102" :rules="formVerify.email" name="email" class="email" autocomplete="off" left-icon="invition" clearable/>
        <!-- 密码 -->
        <p class="mailbox">{{language.l_92}}</p>
        <van-field @click-right-icon="psdFlag = !psdFlag" v-model="password" :placeholder="language.l_110" :rules="formVerify.code" :type="psdFlag ? 'password' : ''" class="email" name="pwd" clearable left-icon="lock" autocomplete="off">
          <template #button>
            <!-- 忘记密码 -->
            <div class="forget" @click="forget">{{language.l_100}}</div>
          </template>
          <template #right-icon><van-icon :name="psdFlag ? 'closed-eye' : 'eye'" color="#FB2C37"/></template>
        </van-field>
        <!-- 登录按钮 -->
        <van-button native-type="submit" color="#FB2C37" class="button_style">{{language.l_3}}</van-button>
      </van-form>
      <!-- 注册按钮 -->
      <div class="register">
        <span class="no_account">{{language.l_112}}</span>
        <van-button @click="regjster" color="#FB2C37" size="mini" class="reg_Button">{{language.l_113}}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {email_login} from "@api";
export default {
  data() {
    return {
      email: "",
      password: "",
      language: this.$store.getters.language,
      psdFlag: true,
      prevRoute: {}
    };
  },
  methods: {
    onSubmit(values) { //提交表单
      const {email, pwd} = values;
      const {prevRoute: {name, params}} = this;
      email_login({email, pwd})
      .then(res => {
        this._handleData(res);
        if(name == 'Register' || name == 'ForgetPwd' || name == 'ConfirmPwd') return this.$router.replace({name: 'Home'})
        if(name) return this.$router.replace({name, params});
        this.$router.replace({name: 'Home'});
      })
    },
    forget() {// 跳转到忘记密码
      this.$router.push({name: 'ForgetPwd'});
    },
    regjster() {  //注册页面
      this.$router.push({name: 'Register'});
    },
    _handleData(data) {  //登入成功存储数据
      // 登录数据处理
      this.$toast.success(this.language.l_114);  //登录成功
      const { token, user_info } = data.data;
      // 存储登录数据 保证未关闭浏览器就算关闭页面后重新打开也不需要重新登录
      this.$Cookies.set("setToken", token);
      this.$Cookies.set("setUserInfo", JSON.stringify(user_info));
      // 将登录数据存入vuex
      this.$store.commit("setToken", token); // 存储token
      this.$store.commit("setUserInfo", user_info); // 存储用户信息
    }
  },
  computed: {
    formVerify() {
      const rules = {
        email: [{required: true, message: this.language.l_109}],  //请填写邮箱
        code: [{required: true, message: this.language.l_111}],  //请填写密码
      };
      return rules;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.$data.prevRoute = from);
  }
};
</script>
<style lang='less' scoped>
#logins {
  background: #fff;
  height: 100%;
  .head_img {
    width: 100%;
    height: 244px;
  }
  .table {
    background: #fff;
    padding: 20px 25.5px;
    .head_name {
      font-size: 24px;
      font-weight: bold;
      color: #013358;
    }
    .mailbox {
      padding: 30px 0 10px 0;
      font-size: 14px;
      font-weight: bold;
      color: #013358;
    }
    .email {
      background: #fef3f1;
      border-radius: 8px;
      /deep/ input::-webkit-input-placeholder {
        color: @c_deep;
      }
      /deep/ .van-field__control {
        padding-left: 8px;
      }
      .forget {
        color: @c_deep;
        height: 24px;
        padding-left: 6px;
        border-left: 2px solid @c_deep;
      }
      /deep/ .van-field__left-icon {
        i {
          color: @c_deep;
        }
      }
    }
    .register {
      display: flex;
      align-items: center;
      justify-content: center;
      .no_account {
        font-size: 12px;
        color: @c_deep;
      }
      .reg_Button{
        padding: 0 15px
      }
    }
    /deep/ .van-field__error-message {
      padding-left: 8px;
    }
    .button_style{
      width: 100%;
      border-radius: 8px;
      margin: 40px 0
    }
  }
}
</style>
